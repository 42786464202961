import React from 'react';

function App() {
    return (
        <div style={{
            backgroundColor: "#191F26",
            color: "white",
            fontFamily: "Arial, sans-serif",
            textAlign: "center",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            height: "100vh",
            margin: "0"
        }}>
            <style>
                {`body { margin: 0; padding: 0; }`}
            </style>
            <img
                src="https://cp.gogaming.com/Views/2a586ea9-42cf-40a0-8fed-d43e0297fa46/Images/LargeLogo.png"
                alt="GoGaming Logo"
                style={{ width: "191px", height: "198px", marginBottom: "20px" }}
            />
            <h1 style={{ fontSize: "32px" }}>
                <span style={{ color: "#fff" }}>Go</span>
                <span style={{ color: "#38aae1" }}>Gaming</span>
            </h1>
            <p style={{ fontSize: "16px", marginTop: "10px" }}>
                GoGaming has discontinued, but existing customers remain supported.
            </p>
            <div style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: "10px",
                marginTop: "10px"
            }}>
                <p style={{ fontSize: "18px", marginTop: "20px" }}>Please visit</p>
                <a href="https://godedicated.com/hosting/dedicated-servers">
                    <img
                        src="https://godedicated.com/images/logo.svg"
                        alt="GoDedicated Logo"
                        style={{ width: "152px" }}
                    />
                </a>
                <p style={{ fontSize: "18px", marginTop: "20px" }}>for Dedicated Servers sales.</p>
            </div>
            <p style={{ fontSize: "18px", marginTop: "20px" }}>If you are an existing customer:</p>
            <div style={{ marginTop: "10px" }}>
                <a href="https://billing.godedicated.com" style={buttonStyle}>Billing Panel</a>
                <a href="https://cp.gogaming.com" style={buttonStyle}>Game Panel</a>
                <a href="https://billing.godedicated.com/submitticket.php" style={buttonStyle}>Support</a>
            </div>
        </div>
    );
}

const buttonStyle = {
    display: "block",
    backgroundColor: "#38aae1",
    color: "white",
    textDecoration: "none",
    padding: "10px 20px",
    margin: "10px",
    borderRadius: "5px",
    fontSize: "16px",
    width: "150px",
    textAlign: "center"
};

export default App;
